document.addEventListener('DOMContentLoaded', function () {
    var closeButton = document.getElementById('noscript-close');
    if (closeButton) {
        closeButton.addEventListener('click', function () {
            var noscriptWarning = document.getElementById('noscript-warning');
            if (noscriptWarning) {
                noscriptWarning.style.display = 'none';
            }
        });
    }
});
